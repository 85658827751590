body {
    /* overflow: hidden; */
}
.notification-container{
    background-color: #EC041C;
    color: #fff;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: 31px;
}
.notification-num{
    font-size: 13px;
    font-family: 'Poppins-SemiBold';
    text-align: center;
}

.Login{
    width: 100%;
    height: 100vh;
    /* padding: 215px 358px; */
    background-image: url('../image/bg/signup-bg1.png');
    background-size: cover;
    padding: 95px 358px;
}
.logo-container{
    text-align: center;
}
.logo img{
    margin-bottom: 82px;
    height: 40px;
}

.Login .button-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Login input{
    width: 100%;
    height: 34px;
    border-radius: 6px;
    border-color: #d1d1d1;
}
.Login .forget-Text{
    float: right;
    margin-top: 14px;
    color: #57a7e6;
    font-size: 16px;
    margin-bottom: 28px;
}
.Login .newAccount-Text{
    text-align: center;
    margin-top: 24px;
    font-size: 16px;
    color: #57a7e6;
}
.Login .loginAdmin-Text{
    text-align: center;
    margin-top: 122px;
    font-size: 16px;
    color: #57a7e6;
}

.Login .customer-Btn{
    background-color: #5c3da4;
    border-radius: 7px;
    width: 116px;
    color: #FFFFFF;
    font-family: Poppins-Medium;
    border: none;
}
.btn-primary:hover {
    color: #fff;
    background-color: #5c3da4 !important;
    border-color: #5c3da4 !important;
}
.btn-primary:active {
    color: #fff;
    background-color: #5c3da4 !important;
    border-color: #5c3da4 !important;
}

.Login .provider-Btn{
    background-color: #F3F4F5;
    border-radius: 7px;
    width: 116px;
    font-family: Poppins-Medium;
    color: #161616;
    border: none;
}

.Login .LoginBtn-container{
    text-align: center;
    margin-top: 84px;
}
.Login .Login-Btn{
    background-color: #5c3da4;
    border-radius: 7px;
    width: 116px;
}
.login-Label{
    font-size: 18px;
    font-family: Poppins-Medium;
}
.login-title{
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 28px;
    font-family:  Poppins-Bold;
}

.textField input::placeholder { 
    color: #707070;
    font-size: 12px;
    font-family: Poppins-Regular;
  }

.forgotPassword{
    background-image: url('../image/bg/60286.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.Login .forgotPass-container {
    text-align: center;
    margin-top: 50px;
}

.Login .confirmPass-container {
    text-align: center;
    margin-top: 44px;
}

.dashboard-content-Title{
    text-align: center;
    font-size: 36px;
    font-family: Poppins-Bold;
    color: #121212;
    margin-top: 6px;
}
.dashboard-content-SubTitle{
    text-align: center;
    font-size: 12px;
    font-family: Poppins-Regular;
    color: #121212;
    margin-bottom: 33px;
    text-decoration: underline;
}
.Home-main-Container{
    padding: 10px 60px;
}
.Home-main-Container .job-card {
    width: 100%;
    height: 100px;
    border: 2px solid #5C3DA4;
    background-color: #fff;
    border-radius: 12px;
    margin-top: 8px;
}
.Home-main-Container .card-Content{
     text-align: center;
     padding: 16px 0px;
}
.Home-main-Container .job-title{
    color: #5C3DA4;
    font-size: 24px;
    font-family: Poppins-SemiBold;
    margin: 0px;
}
.Home-main-Container .job-detail-Text {
    font-size: 12px;
    font-family: Poppins-Regular;
    color: #A2A2A2;
    margin-top: -16px;
}

.Home-main-Container .job-card-provider {
    width: 100%;
    height: 248px;
    background-image: url('../image/provider-status.png');
    background-size: cover;
    /* border: 2px solid #5C3DA4; */
    /* background-color: #fff; */
    border-radius: 12px;
    margin-top: 8px;
}
.Home-main-Container .card-Content-provider{
     text-align: center;
     padding: 20px 0px;
}
.Home-main-Container .job-title-provider{
    color: #5C3DA4;
    font-size: 64px;
    font-family: Poppins-SemiBold;
}
.Home-main-Container .provider-jobDetails-Btn {
    background-color: #5C3DA4;
    width: 408px;
    font-size: 12px;
    font-family: Poppins-Regular;
    color: #fff;
    margin-top: 11px;
    height: 40px;
    border-radius: 8px;
}

.Home-main-Container .status-text{
    font-size: 20px;
    font-family: Poppins-Medium;
    color: #121212;
}
.Home-main-Container .seeAll-text{
    margin-top: 34px;
    margin-bottom: 14px;
    font-size: 18px;
    font-family: Poppins-Regular;
    color: #A2A2A2;  
    text-decoration: underline;  
}
.Home-main-Container .seeAll-text:hover{
    text-decoration: none;  
}

.service-card {
    min-height: 120px;
    width: 100%;
    background: white;
    border-radius: 5px;
    padding: 4px 4px;
    text-align: center;
    overflow: hidden;
}
.service-card:hover {
    box-shadow: 4px 5px 5px 5px #e3e3e3;
  }

.service-card .card-icon{
   
}
.Home-main-Container .service-title{
    font-size: 18px;
    font-family: Poppins-Medium;
    color: #A2A2A2;
    margin-top: 18px;
}
.Home-main-Container .card-icon img{
    margin-top: 20px;
    /* width: 54px; */
}
.Home-main-Container .offer-banner img{
    width: 100%;
    margin-top: -18px;
}
.Home-main-Container .user-card{
    width: 100%;
    border: 1px solid #DFDFDF;
    height: 213px;
    margin-top: 14px;
    border-radius: 8px;
    background-color: #F6F6F8;
    overflow-y: scroll;
}

.Home-main-Container .user-image{
    height: 48px;
    width: 48px;
    background-color: #b1b1b1;
    border-radius: 7px;
    overflow: hidden;
}

.Home-main-Container .user-card-InnerContainer{
    padding: 8px 19px;
}
.Home-main-Container .user-card-title{
    color: #121212;
    font-family: Poppins-SemiBold;
    font-size: 14px;
    margin-top: 2px;
}
.Home-main-Container .user-card-name{
    color: #121212;
    font-family: Poppins-Regular;
    font-size: 12px;
    margin-top: -16px;
}

.Home-main-Container .provider-card{
    height: 100px;
    width: 100%;
    background-color: #F6F6F8;
    border-radius: 13px;
    border-color: #DFDFDF;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Home-main-Container .provider-card-title{
    margin-left: 15px;
    font-size: 15px;
    font-family: Poppins-SemiBold;
    color: #fff;
    margin-top: -12px;
}

.Home-main-Container .provider-card-title3{
    margin-left: 10px;
    font-size: 12px;
    font-family: Poppins-SemiBold;
    color: #fff;
    margin-top: 14px;
    margin-right: 10px;
}

.Home-main-Container .provider-card-progress1 {
    height: 100px;
    width: 100%;
    background-image: url('../image/Rectangle\ 58.png');
    background-size: cover;
    /* background-color: #4a4a9f; */
    border-radius: 13px;
    border-color: #DFDFDF;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 17px;
    padding: 2px 13px;
}
.Home-main-Container .provider-card-progress2 {
    height: 100px;
    width: 100%;
    background-image: url('../image/Rectangle\ 242.png');
    background-size: cover;
    border-radius: 13px;
    border-color: #DFDFDF;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 17px;
    padding: 2px 13px;
}
.Home-main-Container .provider-card-progress3 {
    height: 100px;
    width: 100%;
    background-image: url('../image/Rectangle\ 243.png');
    background-size: cover;
    border-radius: 13px;
    border-color: #DFDFDF;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 17px;
    padding: 2px 13px;
}
.Home-main-Container .provider-card-num{
    font-size: 45px;
    font-family: Poppins-SemiBold;
    color: #fff;
    text-align: center;
    margin: 0px;
}
.Home-main-Container .progress-bar-status{
    font-size: 24px;
    font-family: Poppins-Medium;
    color: #121212;
}
.provider-detail{
    font-size: 10px;
    color: #fff;
    font-family: Poppins-Regular;
    margin-top: 21px;
}


/* .Home-main-Container .card-bg1 {
    background-image: url('../image/Rectangle\ 58.png');
    background-size: cover;
}
.Home-main-Container .card-bg2 {
    background-image: url('../image/Rectangle\ 242.png');
    background-size: cover;
}
.Home-main-Container .card-bg3 {
    background-image: url('../image/Rectangle\ 243.png');
    background-size: cover;
} */

.navbar-title{
    color: #384364;
    font-family: Poppins-SemiBold;
    font-size: 24px;
    margin-bottom: -14px;
}

.logout-Container{
    margin-top: 114px;
}
.version-text{
    font-family: Poppins-Regular;
    font-size: 14px;
    color: #38436473;
    /* color: #384364; */
    margin-bottom: 6px;
    text-align: center;
}
.Logout-Btn{
    color: #fff !important;
    background-color: #D91025 !important;
    border: none !important;
    border-radius: 8px !important;
    width: 196px !important;
    margin-right: 16px !important;
}

.btn-check:focus+.btn-primary, .btn-primary:focus {
    box-shadow: none !important;
}

.image-width{
    width: 100%;
}

::-webkit-scrollbar {
    width: 0; 
    background: transparent;  
}
::-webkit-scrollbar-thumb {
    background: transparent;
}

.custom-modal{
    padding: 6px 0px !important;
}
.custom-modal .modal-title {
    font-size: 36px;
    color: #121212;
    text-align: center;
    font-family: Poppins-Bold;
}
.modal-content{
    padding: 20px 41px;
}

.custom-modal .pickUp-container{
    margin-top: 10px;
}
.custom-modal .pickUp-container textarea{
    width: 100%;
    height: 80px;
    border-color: #DFDFDF;
    border-radius: 8px;
    padding: 12px 22px;
}

.custom-modal .pickup-text{
    font-size: 18px;
    color: #121212;
    font-family: 'Poppins-Medium';
    margin: 0px;
    margin-bottom: 12px;
}

.custom-modal .select-border{
    height: 80px;
    width: 100%;
    border: 1px solid;
    border-radius: 8px;
    border-color: #DFDFDF;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 12px 12px;
}

.custom-modal .date input{
    height: 50px;
    border-radius: 8px;
    border-color: #5c3da46b;
    width: 100%;
    padding: 18px;
}

.custom-modal .months input{
    height: 50px;
    border-radius: 8px;
    border-color: #5c3da46b;
    width: 100%;
    padding: 18px;
}

.custom-modal .time input{
    height: 50px;
    border-radius: 8px;
    border-color: #5c3da46b;
    width: 100%;
    padding: 18px;
}

.custom-modal .custome-time input{
    height: 50px;
    border-radius: 8px;
    border-color: #dfdfdf69;
    width: 100%;
    padding: 10px;
    margin-left: 4px;
    padding: 18px;
}

.custom-modal .pick-MainContainer{
    margin-top: 32px;
}

.custom-modal  .or-text {
    font-size: 14px;
    color: #5C3DA4;
    font-family: Poppins-Medium;
    margin-right: 47px;
    margin-top: 12px;
}
.custom-modal .container-payment{
    height: 120px;
    width: 100%;
    border: 1px solid;
    border-radius: 12px;
    padding: 12px 15px;
}
.custom-modal .modal-footer-text{
    font-size: 14px;
    font-family: Poppins-Regular;
    color: #12121273;
}
.custom-modal .modal-footer-subtext{
    font-size: 14px;
    font-family: Poppins-Medium;
    color: #121212;
}
.custom-modal .modal-foter-input input{
    height: 50px;
    border-radius: 8px;
    border-color: #5c3da46b;
    width: 135px;
    padding: 12px 50px;
}
.custom-modal .submit-pay-Btn {
    background-color: #5C3DA4;
    color: #ffff;
    width: 200px;
    border-radius: 8px;
    font-family: Poppins-Medium;
    font-size: 18px;
    padding: 10px 30px;
}
.custom-modal .modal-content{
    overflow: scroll;
}
.card-setting .custom-moda{
    color: #A2A2A2;
    font-size: 12px;
    font-family: Poppins-Regular;
}

.leaflet-container{
    width: 100%;
    height: 100%;
}
.map-custom-container{
    width: 100%;
    height: 85%;

}

/* ----Job details--- */
.jobDetails .profile-container{
    height: 70px;
    width: 70px;
    /* background-color: #b5b5b5; */
    border-radius: 8px;
    overflow: hidden;
}

.jobDetails .right-icon{
    margin-left: 20px;
    margin-right: 20px;
    font-size: 20px;
    color: #5C3DA4;
}
.jobDetails .paid-service{
    font-family: Poppins-Regular;
    color: #D99329;
    font-size: 24px;
    text-align: center;
}

.jobDetails .accept-btn{
    background-color: #06B82A;
    color: #ffff;
    width: 200px;
    border-radius: 8px;
    font-family: Poppins-Medium;
    font-size: 18px;
    border: none;
}
.jobDetails .reject-btn{
    text-align: center;
    color: #D91025;
    font-size: 18px;
    font-family: Poppins-Medium;
    cursor: pointer;
}
.jobDetails .reject-btn:hover{
    text-decoration: underline;
}

.jobDetails .reject-container{
    text-align: center;
    margin-top: 20px;
}

/* ==== ride finished === */
.rideFinished .finished-text {
    font-size: 30px;
    font-family: Poppins-SemiBold;
    color: #121212;
    margin-top: 16px;
    text-align: center;
}
.rideFinished .profile-pic-container {
    height: 110px;
    width: 110px;
    background-color: #b5b5b5;
    border-radius: 8px;
}

.rideFinished .user-name-finished {
    color: #121212;
    font-size: 30px;
    font-family: Poppins-SemiBold;
    text-align: center;
    margin-top: 20px;
}

.rideFinished .paymetText-container{
    background-color: #D99329;
    height: 50px;
    border-radius: 12px;
    padding: 10px 20px;
    margin-bottom: 22px;
}

.rideFinished .paymentcard-text{
    color: #fff;
    font-family: Poppins-Regular;
    text-align: center;
    font-size: 18px;
}

.bookingDetails .online{
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: #51EC05;
    position: absolute;
    right: 394px;
    top: 341px;
}
.online{
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: #51EC05;
    position: absolute;
    right: 218px;
    /* top: 341px; */
}

.notification-user-badge{
    font-size: 12px !important;
    color: #F6F6F6;
    margin-right: 4px;
}

.text-underline{
    text-decoration: underline !important;
}

.btn-primary:hover{
    background-color: #8777ad !important;
    color: #fff !important;
    border-color: #8777ad !important;
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #020202 !important;
    background-color: #F3F4F5 !important;
    border-color: #000000 !important;
}


.btn-danger:hover {
    color: #fff !important;
    background-color: #b96e76 !important;
    border-color: #b02a37 !important;
}

.loader-container{
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: #58595a0f;
    padding: 200px 30px;
}

.downloadChat-Icon{
    color: #fdfdfd;
    font-size: 24px !important;
    margin-right: 8px;
}

.offer-margin{
    margin-top: 64px;
}
.subTitle-margin{
    margin-bottom: 130px;
}

.modal-width{
    max-Width: 700px; 
    width: 100%;
}

@media only screen and (max-width: 1024px) {
    .Login {
        padding: 61px 182px;
    }
    .login-title {
        font-size: 18px;
    }
    .login-Label {
        font-size: 17px;
    }

    /* -- profile -- */
    .profile .profile-card-container {
        height: 572px;
    }
    .profile .sub-Title {
        font-size: 20px;
    }
    .profile .user-Detail-container {
        height: 370px;
    }
    .profile .user-data {
        font-size: 12px;
    }
    .profile .user-Title {
        font-size: 14px;
    }
    .Home-main-Container .user-card {
        height: 152px;
        margin-top: -2px;
    }
    .Home-main-Container .user-image {
        height: 38px;
        width: 38px;
    }
    .Home-main-Container .user-card-name {
        font-size: 11px;
    }
    .Home-main-Container .user-card-title {
        font-size: 13px;
    }
    .Home-main-Container .service-title {
        font-size: 13px;
    }
    .Home-main-Container .card-icon img {
        width: 60px;
    }
    .profile .edit-profile {
        padding-bottom: 12px;
    }

    /* == chat == */
    .online {
        right: 148px;
    }

    /* provider profile */
    .provider-userCard {
        height: 220px !important;
    }
    .bookingDetails .online {
        right: 325px;
        top: 355px;
    }
    .bookingDetails .profile-pic {
        top: 264px;
    }
    .jobDetails .accept-btn {
        width: 150px;
    }
}

@media (min-width: 768px) and (max-width: 991px){
    .Login {
        padding: 61px 108px;
    }
    .offer-margin{
        margin-top: 30px;
    }
    .dashboard-content-Title {
        margin-top: 48px;
    }
    .Home-main-Container .status-text {
        font-size: 18px;
    }
    .Home-main-Container .progress-bar-status {
        font-size: 20px;
    }
    .Home-main-Container .provider-card-num {
        font-size: 38px;
    }
}

@media only screen and (max-width: 767px) {
    .Login .button-container {
        margin-bottom: 10px;
    }
    .Login .LoginBtn-container {
        margin-top: 68px;
    }
    .Home-main-Container .user-image {
        height: 50px;
        width: 50px;
    }
    .service-card {
        margin-bottom: 12px;
    }
    .Home-main-Container .user-card-InnerContainer {
        padding: 8px 38px;
    }
    .Home-main-Container .user-card {
        height: 190px;
    }
    .dashboard-content-Title {
        margin-top: 50px;
    }
    .subTitle-margin {
        margin-bottom: 30px;
    }
    .online {
        right: 182px;
    }
    .bookingDetails .online {
        right: 326px;
        top: 392px;
    }
    .Home-main-Container .job-card-provider {
        height: 200px;
    }
    .Home-main-Container .job-title-provider {
        font-size: 38px;
    }
    .Home-main-Container .card-Content-provider {
        padding: 38px 0px;
    }
}

@media only screen and (max-width: 425px) {
    .Login {
        padding: 60px 64px;
    }
    .Home-main-Container {
        padding: 10px 2px;
    }
    .dashboard-content-Title {
        font-size: 28px;
    }
    .css-qivjh0-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
        font-size: 10px !important;
    }
    .Home-main-Container .user-card-title {
        margin-left: 14px;
    }
    .Home-main-Container .user-card-name {
        margin-left: 14px;
    }
    .modal-width{
        max-Width: 700px; 
        width: auto;
    }
    .Home-main-Container .provider-jobDetails-Btn {
        width: 80%;
    }
    .mapButton-mobView{
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;
    }
}

@media only screen and (max-width: 375px) {
    .Login {
        padding: 60px 44px;
    }
    .Home-main-Container .user-image {
        height: 40px;
        width: 40px;
    }
    .dashboard-content-Title {
        font-size: 24px;
    }
    .online {
        right: 94px;
    }
    .Home-main-Container .progress-bar-status {
        font-size: 20px;
    }
    .mapButton-mobView{
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;
    }
}

@media only screen and (max-width: 320px) {
    .Login {
        padding: 60px 26px;
    }
    .profile .edit-profile {
        padding-bottom: 0px;
    }
    .jobDetails .profile-container {
        height: 60px;
        width: 60px;
    }
    .profile .user-data {
        font-size: 8px;
    }
    .Home-main-Container .status-text {
        font-size: 16px;
    }
    .Home-main-Container .user-card-InnerContainer {
        padding: 8px 10px;
    }
    .Home-main-Container .user-image {
        height: 35px;
        width: 35px;
    }
    .Home-main-Container .user-card-title {
        font-size: 12px;
    }
    .recent-rating-no{
        font-size: 10px;
    }
    .dashboard-content-Title {
        font-size: 20px;
    }
    .dashboard-content-SubTitle {
        font-size: 11px;
    }
    .providerProfile .certified-badge {
        font-size: 14px;
    }
    .Home-main-Container .seeAll-text {
        font-size: 15px;
    }
    .offer-margin {
        margin-top: 18px;
    }
    .driveBooking-rating{
        font-size: 12px;
    }
    .custom-modal .modal-title {
        font-size: 24px;
    }
    .custom-modal .pickup-text {
        font-size: 15px;
    }
    .card-setting{
        font-size: 9px;
    }

}