.contact .contact-container{
    margin-top: 76px;
}
.contact .contact-card{
    background-color: #fff;
    height: 633px;
    width: 100%;
    margin-bottom: 22px;
    border-radius: 8px;
    padding-top: 42px;
    padding-left: 130px
}

.contact .query-title{
    color: #121212;
    font-size: 18px;
    font-family: Poppins-Medium;
}

.contact .query-input textarea{
    width: 76%;
    height: 120px;
    border-radius: 8px;
    border-color: #DFDFDF;
    padding: 10px 25px;
}
.contact .message-input textarea{
    width: 412px;
    height: 224px;
    border-radius: 8px;
    border-color: #DFDFDF;
    padding: 10px 25px;
}
textarea::placeholder {
    color: #707070;
    font-size: 12px;
    font-family: Poppins-Regular;
}

.contact .image-container img{
    width: 362px;
    margin-top: -25px;
    margin-left: -38px;
}
.contact .sub-message{
    font-size: 9px;
    color: #121212;
    font-family: 'Poppins-Medium';
}

.contact .submit-Btn{
    width: 148px;
    border-radius: 8px;
    background-color: #5C3DA4;
    color: #fff;
}

/* -----Service------- */

.service .service-card-container {
    background-color: #fff;
    height: 633px;
    width: 100%;
    margin-bottom: 22px;
    border-radius: 8px;
    padding-top: 42px;
    padding-left: 30px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.service .service-title {
    font-size: 18px;
    font-family: Poppins-Medium;
    color: #A2A2A2;
    margin-top: 10px;
}
.service .card-icon img {
    margin-top: 12px;
    width: 50px;
}
.service .service-description{
    color: #121212;
    font-family: Poppins-Medium;
    font-size: 15px;
}

.service .bookNow-Btn{
    width: 148px;
    border-radius: 8px;
    background-color: #5C3DA4;
    color: #fff;
}

.service .added-Btn {
    width: 148px;
    border-radius: 8px;
    background-color: #A2A2A2;
    color: #fff;
    border: none;
}

/* ------Profile ------- */

.profile .profile-card-container {
    background-color: #fff;
    height: 633px;
    width: 100%;
    margin-bottom: 22px;
    border-radius: 8px;
    /* padding-top: 42px;
    padding-left: 30px; */
    padding: 42px 30px;
}
.profile .profile-pic {
    height: 110px;
    width: 110px;
    background-color: transparent;
    position: absolute;
    top: 110px;
    border-radius: 8px;
    overflow: hidden;
}
.profile .user-name{
    color: #121212;
    font-size: 30px;
    font-family: Poppins-SemiBold;
    text-align: center;
    margin-top: 40px;
}

.profile .sub-Title{
    color: #121212;
    font-size: 24px;
    font-family: Poppins-Medium;
}

.profile .edit-profile{
    color:#A2A2A2;
    font-size: 12px;
    font-family: Poppins-Regular;
}

.profile .user-Detail-container{
    height: 426px;
    width: 100%;
    background-color: #F6F6F6;
    border-color: #DFDFDF;
}

.profile .userDetail-subContainer{
    padding: 38px;
}

.profile .user-Title{
    color: #121212;
    font-size: 18px;
    font-family: Poppins-SemiBold;
    margin: 0;
}
.profile .user-data{
    color: #5C3DA4;
    font-size: 16px;
    font-family: Poppins-Medium;
}

.profile .addNew-Btn{
    width: 148px;
    border-radius: 8px;
    background-color: #5C3DA4;
    color: #fff;
}

.profile .radioBtn-Label{
    color: #A2A2A2;
    font-family: Poppins-Regular;
    font-size: 12px;
    margin-right: 8px;
}

.profile .doodle-container img{
    position: absolute;
    top: 153px;
    left: 504px;
    width: 134px;
    left: 761px;
}

.profile .paymentDelete:hover {
    box-shadow: rgb(88 95 102) 0px 8px 24px;
    color: #d16868 !important;
}

.profile .addNewCard-btn{
   text-Align: center;  
   margin-Top: 20px;
}
.profile .payment-card-default{
    float: right; 
    margin-Left: 68px; 
}
.profile .payment-card-options{
    float: right; 
    margin-Left: 9px; 
}

/* ------Edit Profile ------- */

.editProfile .profile-card-container {
    background-color: #fff;
    height: 633px;
    width: 100%;
    margin-bottom: 22px;
    border-radius: 8px;
    /* padding-top: 42px;
    padding-left: 30px; */
    padding: 42px 30px;
}
.editProfile .profile-pic {
    height: 110px;
    width: 110px;
    /* background-color: #b5b5b5; */
    position: absolute;
    top: 110px;
    border-radius: 8px;
    overflow: hidden;
}
.editProfile .user-name{
    color: #121212;
    font-size: 30px;
    font-family: Poppins-SemiBold;
    text-align: center;
    margin-top: 40px;
}

.editProfile .sub-Title{
    color: #121212;
    font-size: 24px;
    font-family: Poppins-Medium;
}

.editProfile .user-Detail-container{
    /* height: 426px; */
    height: 366px;
    width: 100%;
    background-color: #F6F6F6;
    border-color: #DFDFDF;
}

.editProfile .addNew-Btn{
    width: 148px;
    border-radius: 8px;
    background-color: #5C3DA4;
    color: #fff;
}

.editProfile .SaveChanges-container{
    text-align: center;
    margin-top: 14px;
}

.editProfile .saveChanges-Btn{
    background-color: #5C3DA4;
    color: #fff;
    width: 165px;
    border-radius: 7px;
    font-size: 17px;
    font-family: Poppins-Regular;
    border: none !important;
}
.editProfile .saveChanges-Btn:focus{
    background-color: #5C3DA4 !important;
    color: #fff !important;
    border: none !important;
}
.editProfile .editVector img{
    width: 272px;
    position: relative;
    top: -128px;
    left: -46px;
}

.editProfile .edit-container{
    background-color: #f7f7f7;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    margin-left: 97px;
    box-shadow: 2px 2px 2px #afaeae40;
    /* right: 447px; */
}
.editProfile .edit-container input{
    position: absolute;
    margin-left: -18px;
    width: 24px;
    opacity: 0;
}

.editProfile  .edit-pen-icon{
    color: #5C3DA4;
    font-size: 15px;
    margin-top: 6px;
    margin-left: 7px;
}

/* ------Notification ------- */

.notification .profile-card-container {
    background-color: #fff;
    height: 633px;
    width: 100%;
    margin-bottom: 22px;
    border-radius: 8px;
    /* padding: 42px 30px; */
    padding: 14px;
    overflow-y: scroll;
}

.notification .notification-Card{
    width: 100%;
    height: 86px;
    background-color: #5C3DA4;
    border-radius: 8px;
    margin-bottom: 14px;
}
.notification .image-container{
    height: 55px;
    width: 55px;
    /* background-color: #b1b1b1; */
    border-radius: 7px;
    overflow: hidden;
}

.notification .detail-text{
    font-size: 15px;
    color: #FFFFFF;
    font-family: Poppins-Medium;
    margin-top: 12px;
    text-align: center;
}

.notification .notification-Btn{
    width: 145px;
    background-color: #FFFFFF;
    color: #5C3DA4;
    border-radius: 7px;
    font-family: 'Poppins-Medium';
    font-size: 14px;
}

.notification .user-Name{
    color: #F6F6F6;
    font-size: 18px;
    font-family: Poppins-SemiBold;
    margin: 0px;
}
.notification .certified-text {
    font-size: 10px;
    font-family: 'Poppins-Regular';
    color: #f6f6f68c;
}

.gray-Color{
    background-color: #A2A2A2;
    border-radius: 8px;
    margin-bottom: 14px;
}
.notification .status-text{
    color: #5C3DA4;
    font-size: 14px;
    font-family: 'Poppins-SemiBold';
}


/* ------Booking Details ------- */

.bookingDetails .profile-card-container {
    background-color: #fff;
    /* height: 619px; */
    height: 579px;
    width: 100%;
    margin-bottom: 22px;
    border-radius: 8px;
    padding: 34px 120px;
}
.bookingDetails .profile-pic {
        height: 80px;
        width: 80px;
        background-color: #b5b5b5;
        position: absolute;
        top: 249px;
        border-radius: 8px;
        overflow: hidden;
}
.bookingDetails .user-name{
    color: #121212;
    font-size: 30px;
    font-family: Poppins-SemiBold;
    text-align: center;
    margin-top: 72px;
}

.bookingDetails .sub-Title{
    color: #121212;
    font-size: 24px;
    font-family: Poppins-Medium;
}

.bookingDetails .certificate-text{
    margin-left: 20px;
    margin-right: 20px;
    color: #12121266;
    font-size: 17px;
    font-family: Poppins-Regular;
}

.bookingDetails .certified-badge{
    margin-bottom: 12px;
    color: #12121266;
    margin-bottom: 17px;
}

.bookingDetails .rating{
    margin-right: 10px;
    color: #12121266;
}

.bookingDetails .star-icon{
    margin-bottom: 15px;
}

.bookingDetails .provider-card{
    height: 100px;
    width: 100%;
    background-color: #F6F6F8;
    border-radius: 13px;
    border-color: #DFDFDF;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bookingDetails .provider-card-title{
    margin-left: 15px;
    font-size: 15px;
    font-family: 'Poppins-SemiBold';
    color: #5C3DA4;
    margin-top: 14px;
}

.bookingDetails .about-container{
    width: 100%;
    border: 1px solid #DFDFDF;
    height: 213px;
    margin-top: 14px;
    border-radius: 8px;
    background-color: #FFFFFF;
    padding: 12px 26px;
}

.bookingDetails .about-para{
    font-size: 15px;
    color: #1212126b;
    font-family: 'Poppins-Regular';
}

.bookingDetails .goBack-Btn{
    background-color: #D99329;
    color: #ffff;
    width: 200px !important;
    border-radius: 8px;
    font-family: Poppins-Medium;
    font-size: 18px;
}
.bookingDetails .assignJob-Btn{
    background-color: #5C3DA4;
    color: #ffff;
    width: 200px;
    border-radius: 8px;
    font-family: Poppins-Medium;
    font-size: 18px;
    margin-right: 39px;
}
.bookingDetails .chat-Btn{
    background-color: #4CA5F3;
    color: #ffff;
    width: 200px;
    border-radius: 8px;
    font-family: Poppins-Medium;
    font-size: 18px;
}
.bookingDetails .summary-container{
    width: 100%;
    height: 70px;
}
.bookingDetails .user-card-content {
    width: 100%;
    border: 1px solid #DFDFDF;
    height: 213px;
    margin-top: 14px;
    border-radius: 8px;
    background-color: #FFFFFF;
}
.bookingDetails .user-card-InnerContainer {
    padding: 22px 22px;
}
.bookingDetails .user-card-title {
    margin: 0;
    font-size: 14px;
    font-family: 'Poppins-Regular';
    color: #12121282;
}

.bookingDetails .user-card-para {
    margin: 0;
    font-size: 10px;
    font-family: Poppins-Medium;
    color: #121212;
}
.bookingDetails .user-card-data {
    margin: 0;
    font-size: 14px;
    font-family: Poppins-Medium;
    color: #121212;
}

/* ------Booking Finished ------- */

.bookingFinished .profile-card-container {
    background-color: #fff;
    height: 630px;
    width: 100%;
    margin-bottom: 22px;
    border-radius: 8px;
    padding: 42px 30px;
}
.bookingFinished .profile-pic {
    height: 110px;
    width: 110px;
    background-color: #b5b5b5;
    position: absolute;
    top: 122px;
    border-radius: 8px;
    overflow: hidden;
}
.bookingFinished .user-name{
    color: #121212;
    font-size: 30px;
    font-family: Poppins-SemiBold;
    text-align: center;
    margin-top: 30px;
}

.bookingFinished .sub-Title{
    color: #121212;
    font-size: 24px;
    font-family: Poppins-Medium;
}

.bookingFinished .certificate-text{
    margin-left: 20px;
    margin-right: 20px;
    color: #12121266;
}

.bookingFinished .certified-badge{
    margin-bottom: 12px;
    color: #12121266;
}

.bookingFinished .rating{
    margin-right: 10px;
    color: #12121266;
}

.bookingFinished .star-icon{
    margin-bottom: 15px;
}

.bookingFinished .provider-card{
    height: 100px;
    width: 100%;
    background-color: #F6F6F8;
    border-radius: 13px;
    border-color: #DFDFDF;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bookingFinished .provider-card-title{
    margin-left: 15px;
    font-size: 15px;
    font-family: 'Poppins-SemiBold';
    color: #5C3DA4;
    margin-top: 14px;
}

.bookingFinished .about-container{
    width: 100%;
    border: 1px solid #DFDFDF;
    height: 213px;
    margin-top: 14px;
    border-radius: 8px;
    background-color: #F6F6F8;
    padding: 12px 26px;
}

.bookingFinished .about-para{
    font-size: 15px;
    color: #1212126b;
    font-family: 'Poppins-Regular';
}

.bookingFinished .goBack-Btn{
    background-color: #D99329;
    color: #ffff;
    width: 200px !important;
    border-radius: 8px;
    font-family: Poppins-Medium;
    font-size: 18px;
}
.bookingFinished .assignJob-Btn{
    background-color: #5C3DA4;
    color: #ffff;
    width: 200px;
    border-radius: 8px;
    font-family: Poppins-Medium;
    font-size: 18px;
    margin-right: 39px;
}
.bookingFinished .chat-Btn{
    background-color: #4CA5F3;
    color: #ffff;
    width: 200px;
    border-radius: 8px;
    font-family: Poppins-Medium;
    font-size: 18px;
}
.bookingFinished .summary-container{
    width: 100%;
    height: 70px;
}
.bookingFinished .user-card-content {
    width: 100%;
    border: 1px solid #DFDFDF;
    height: 378px;
    border-radius: 8px;
    background-color: #F6F6F8;
    margin-top: 4px;
}
.bookingFinished .user-card-InnerContainer {
    padding: 22px 22px;
}
.bookingFinished .user-card-title {
    color: #121212;
    font-family: Poppins-Medium;
    font-size: 18px;
    margin-bottom: 14px;
}

.bookingFinished .user-card-para {
    margin: 0;
    font-size: 10px;
    font-family: Poppins-Medium;
    color: #121212;
}
.bookingFinished .user-card-data {
    margin: 0;
    font-size: 14px;
    font-family: Poppins-Medium;
    color: #121212;
}

.bookingFinished .review-input textarea{
    width: 100%;
    height: 150px;
    border-radius: 8px;
    background-color: #FFFFFF;
    border-color: #DFDFDF;
    padding: 14px 14px;
}

.bookingFinished .complete-title{
    font-size: 21px;
    color: #121212;
    font-family: Poppins-SemiBold;
    text-align: center;
    /* float: right; */
}

.bookingFinished .review-Card{
    width: 50%;
    height: 46px;
    border-radius: 8px;
    background-color: #FFFFFF;
    border-color: #DFDFDF;
    padding: 10px 10px;
    text-align: center;
    margin-bottom: 10px;
}
.bookingFinished .complete-vector img{
    width: 460px;
    margin-left: -162px;
    margin-top: -117px;
}

.bookingFinished .complete-status{
    text-align: center;
    margin-top: -15
    px
    ;
    color: #06B82A;
    font-size: 14px;
    font-family: 'Poppins-Medium';
    text-decoration: underline;
}


/* ------ Chat View ------- */

.chatView .profile-card-container {
    background-color: transparent;
    /* height: 619px; */
    height: 579px;
    width: 100%;
    margin-bottom: 22px;
    border-radius: 8px;
    padding: 0px 0px;
}
.chatView .profile-pic {
    height: 80px;
    width: 80px;
    background-color: #b5b5b5;
    position: absolute;
    top: -42px;
    border-radius: 8px;
    overflow: hidden;
}
.chatView .user-name{
    color: #121212;
    font-size: 30px;
    font-family: Poppins-SemiBold;
    text-align: center;
    margin-top: 50px;
}

.chatView .sub-Title{
    color: #121212;
    font-size: 24px;
    font-family: Poppins-Medium;
}

.chatView .certificate-text{
    margin-left: 20px;
    margin-right: 20px;
    color: #12121266;
}

.chatView .certified-badge{
    margin-bottom: 12px;
    color: #707070;
}

.chatView .rating{
    margin-right: 10px;
    color: #12121266;
}

.chatView .star-icon{
    margin-bottom: 15px;
}

.chatView .provider-card{
    height: 100px;
    width: 100%;
    background-color: #F6F6F8;
    border-radius: 13px;
    border-color: #DFDFDF;
    display: flex;
    justify-content: center;
    align-items: center;
}
.chatView .provider-card-title{
    margin-left: 15px;
    font-size: 15px;
    font-family: 'Poppins-SemiBold';
    color: #5C3DA4;
    margin-top: 14px;
}

.chatView .about-container{
    width: 100%;
    border: 1px solid #DFDFDF;
    height: 213px;
    margin-top: 14px;
    border-radius: 8px;
    background-color: #F6F6F8;
    padding: 12px 26px;
}

.chatView .about-para{
    font-size: 15px;
    color: #1212126b;
    font-family: 'Poppins-Regular';
}

.chatView .goBack-Btn{
    background-color: #D99329;
    color: #ffff;
    width: 200px !important;
    border-radius: 8px;
    font-family: Poppins-Medium;
    font-size: 18px;
}
.chatView .assignJob-Btn{
    background-color: #5C3DA4;
    color: #ffff;
    width: 100%;
    border-radius: 8px;
    font-family: Poppins-Medium;
    font-size: 18px;
}
.chatView .chat-Btn{
    background-color: #4CA5F3;
    color: #ffff;
    width: 200px;
    border-radius: 8px;
    font-family: Poppins-Medium;
    font-size: 18px;
}
.chatView .summary-container{
    width: 100%;
    height: 70px;
}
.chatView .user-card-content {
    width: 100%;
    border: 1px solid #DFDFDF;
    height: 213px;
    margin-top: 14px;
    border-radius: 8px;
    background-color: #F6F6F8;
}
.chatView .user-card-InnerContainer {
    padding: 22px 22px;
}

.chatView .chat-user-container{
    width: 100%;
    height: 590px;
    background-color: #fff;
    padding: 20px 0px;
    overflow-y: scroll;
    overflow-x: hidden;
    border-color: #DFDFDF;
    border-radius: 10px;
}
.chatView .chat-messenger-container{
    width: 100%;
    height: 590px;
    background-color: #fff;
    overflow: hidden;
    border-color: #DFDFDF;
    border-radius: 10px;
}

.chatView .sidebar-user {
    height: 50px;
    width: 50px;
    background-color: #b5b5b5;
    /* position: absolute; */
    /* top: -42px; */
    border-radius: 8px;
    overflow: hidden;
}
.chatView  .sidebar-user-name{
    font-size: 18px;
    font-family: 'Poppins-Regular';
    color: #121212;
    margin-top: 12px;
    cursor: pointer;
}

.chatView  .user-chatCard{
    padding: 16px 31px;
}

.chatView .All-messages-container{
    width: 100%;
    height: 370px;
    /* background-color: aliceblue; */
    padding: 28px 20px;
    overflow-y: scroll;
}

.chatView .chat-box-mainContainer{
    padding: 10px 14px;
}

.chatView .typing-Container{
    width: 100%;
    height: 70px;
    background-color: #F1F1F1;
    border-radius: 8px;
    padding: 22px 10px;
}
.chat-profile{
    height: 26px;
    width: 26px;
    background-color: #363535;
    margin-top: -10px;
    overflow: hidden;
}
.chatView .message-box{
    /* background-color: #F1F1F1;
    border: none;    */
        background-color: #F1F1F1;
        border: none;
        width: 228px;
        margin-top: -10px;
}
.chatView .message-box2 {
    background-color: #F1F1F1;
    border: none;
    width: 399px;
    margin-top: -10px;
}
.chatView .message-box textarea {
    border: none;
    background-color: #F1F1F1;
    height: 30px;
}
.chatView .message-box .inputEmoji{
   font-size: 11px !important;
}
.react-input-emoji--container{
    font-size: 11px !important;
}

.react-emoji-picker--wrapper {
    right: -62px !important;
}

.react-input-emoji--input {
    height: 40px !important;
    overflow-y: scroll !important; 
    background-color: #F1F1F1 !important;
}
.react-input-emoji--container {
    border: none !important;
}

.chatView .upload-btn-wrapper{
    width: 12px;
    height: 27px;
    /* background-color: aquamarine; */
}

.chatView .message-box textarea ::placeholder{
    font-size: 15px;
    font-family: 'Poppins-Regular';
    color: #a2a2a278;  
}

.upload-btn-wrapper input[type=file] {
    /* width: 10px;
    height: 10px;
    position: absolute;
    left: 338px;
    top: 33px; */
    opacity: 0;
    width: 20px;
    height: 20px;
    position: relative;
  }
.react-input-emoji--button svg {
    width: 20px;
    fill: #5C3DA4 !important; 
}


.incoming{
    display: flex;
}
.outgoing{
    display: flex;
    margin-top: 22px;
    flex-direction: row-reverse;
}

.incoming .chat-picture-incoming{
    height: 48px;
    width: 48px;
    background-color: #363535;   
    overflow: hidden;
    border-radius: 8px;
}

.incoming .chat-message-incoming{
    /* height: 66px; */
    width: 250px;
    background-color: #5C3DA4;
    margin-top: -10px;
    overflow: hidden;
    border-radius: 8px;
    margin-left: 14px;
    padding: 12px 16px;
    margin-bottom: 16px;
}

.outgoing .chat-picture-outgoing{
    height: 48px;
    width: 48px;
    background-color: #363535;   
    overflow: hidden;
    border-radius: 8px;
}

.outgoing .chat-message-outgoing{
    /* height: 66px; */
    width: 250px;
    background-color: #6D6D6D;
    margin-top: -10px;
    overflow: hidden;
    border-radius: 8px;
    margin-left: 14px;
    margin-right: 14px;
    padding: 12px 16px;
    margin-bottom: 16px;
}

.incoming .chat-message-incoming p{
    font-size: 14px;
    color: #fff;
    font-family: Poppins-Regular;    
}
.outgoing .chat-message-outgoing p{
    font-size: 14px;
    color: #fff;
    font-family: Poppins-Regular;    
}

.bookingCard-padding{
    height: 648px !important;
    padding: 14px 120px !important;
}

.notificationDetail-container{
    display: flex;
    justify-Content: center;
    align-Items: center;
    height: 86px;
}

.notification-btn-container{
    text-Align: center; 
    margin-Top: 20px;
} 

@media only screen and (max-width: 1024px) {
    /* -- profile -- */
    .profile .profile-card-container {
        height: 572px;
    }
    .profile .sub-Title {
        font-size: 20px;
    }
    .profile .user-Detail-container {
        height: 370px;
    }
    .profile .user-data {
        font-size: 12px;
    }
    .profile .user-Title {
        font-size: 14px;
    }
    .profile .addNewCard-btn{
        margin-Top: 0px;
     }
     .profile .userContainer_height{
        height: 314px;
     }
     .profile .userDetail-subContainer {
        padding: 18px;
    }
    .profile .doodle-container img {
        left: 631px;
    }

    /* === Services === */
    .service .service-card-container {
        height: 572px;
    }
    .service .bookNow-Btn {
        width: 114px;
    }
    .service .service-title {
        font-size: 14px;
    }

    /* === Contact === */
    .contact .contact-card {
        height: 572px ;
    }
    .contact .contact-card {
        height: 633 px;
        padding-left: 80px;
    }
    .contact .image-container img {
        width: 314px;
    }
    .contact .message-input textarea {
        width: 232px;
        height: 176px;
    }
    .logout-Container {
        margin-top: 30px;
    }
    .bookingDetails .certificate-text {
        font-size: 12px;
    }
    .jobDetails .paid-service {
        font-size: 14px;
    }

    /* Edit Profile */
    .editProfile .profile-card-container {
        height: 572px;
    }
    .editProfile .editVector img {
        width: 202px;
        top: -14px;
        left: -42px;
    }
    .editProfile .user-name {
        font-size: 26px;
        margin-top: 20px;
    }
    .editProfile .SaveChanges-container {
        margin-top: 8px;
    }

    /* Active booking details */
    .bookingDetails .about-container {
        padding: 12px 12px;
    }
    .bookingCard-padding {
        padding: 14px 80px !important;
    }
    .bookingDetails .user-card-data {
        margin-left: 10px;
        font-size: 12px;
    }
    /* .bookingDetails .chat-Btn {
        margin-left: -72px;
    } */

    .bookingDetails .assignJob-Btn {
        width: 150px;
    }
    .bookingDetails .chat-Btn {
        width: 150px;
    }
    .bookingDetails .profile-card-container {
        padding: 34px 71px;
    }

    /* ==Chat== */
    .chatView .chat-user-container {
        height: 500px;
    }
    .chatView .All-messages-container {
        height: 300px;
    }
    .chatView .chat-messenger-container {
        height: 500px;
    }
    .chatView .user-name {
        font-size: 20px;
    }
    .chatView .user-chatCard {
        padding: 16px 18px;
    }
    .chatView .assignJob-Btn {
        font-size: 14px;
    }

    /* == Notification == */
    .notification .notification-Btn {
        width: auto;
    }

    /* == profile == */
    .profile .payment-card-default {
        margin-Left: 30px;
    }
    .profile .payment-card-options {
        margin-Left: -30px;
    }

    .bookingDetails .user-name {
        font-size: 24px;
    }
    .bookingDetails .profile-pic {
        top: 265px;
    }
    
}

@media (min-width: 768px) and (max-width: 991px) {
    .profile .profile-pic {
        top: 166px;
    }
    .profile .doodle-container img {
        left: 374px;
        top: 210px;
    }
    .bookingDetails .profile-pic {
        top: 306px;
    }
    .bookingDetails .user-name {
        font-size: 30px;
    }
}

@media (max-width: 767px) {
    .profile .profile-pic {
        top: 166px;
    }
    .profile .doodle-container img {
        left: 377px;
        top: 209px;
    }
    .profile .profile-card-container {
        height: auto;
    }
    .profile .sub-Title {
        margin-top: 16px;
    }

    /* chat */
    .chatView .chat-user-container {
        height: 124px;
        margin-bottom: 60px;
        overflow-y: hidden;
        overflow-x: scroll;
        padding: 8px 0px;
    }
    .mobile-View{
        display: flex;
    }
    .chatView .sidebar-user-name {
        font-size: 14px;
        margin-top: 10px;
    }
    .chatView .profile-pic {
        height: 70px;
        width: 70px;
    }
    .chatView .chat-messenger-container {
        height: 555px;
        margin-bottom: 14px;
    }
    .chatView .message-box {
        width: 360px;
    }
    
    /* == Services == */
    .service .service-card-container {
        padding-left: 22px;
        padding-right: 22px;
    }
    /* == contact us ==  */
    .contact .image-container img {
        width: 268px;
    }

    /* == active Booking details == */
    .bookingDetails .profile-pic {
        top: 308px;
    }
    .bookingDetails .profile-card-container {
        height: auto !important;
    }

    /* == Notification == */
    .notification .image-container {
        height: 50px;
        width: 50px;
    }
    .notification .user-Name {
        font-size: 14px;
    }
    .notification .certified-text {
        font-size: 8px;
    }
    .notification-user-badge {
        font-size: 10px !important;
    }
    .notification .notification-Btn {
        margin-top: 6px;
        font-size: 12px;
    }
    .notification .detail-text {
        font-size: 13px;
    }
    .editProfile .profile-pic {
        top: 160px;
    }
    .editProfile .user-Detail-container {
        height: auto;
        margin-bottom: 20px;
    }
    .editProfile .profile-card-container {
        height: auto;
    }
    .editProfile .editVector img {
        left: 94px;
    }
    .rating-container {
        width: 70px;
    }
    .map-custom-container {
        height: 360px;
    }
   
}

@media (max-width: 425px) {
    .editProfile .editVector img {
        left: 17px;
    }
    .contact .image-container img {
        display: none;
    }
    .contact .contact-card {
        padding-left: 36px;
    }
    .contact .query-input textarea {
        width: 88%;
    }
    .contact .message-input textarea {
        width: 88%;
    }
    .service .service-card-container {
        padding-left: 10px;
        padding-right: 10px;
    }
    .service .service-description {
        text-align: center;
    }
    .booknow-Btn-mobileView{
        text-align: center;
    }
    .bookingDetails .profile-card-container {
        padding: 34px 30px !important;
    }
    .bookingDetails .online {
        right: 148px;
        top: 375px;
    }
    .bookingDetails .profile-pic {
        top: 286px;
    }
    .profile .doodle-container img {
        left: 204px;
        top: 209px;
    }
    .editProfile .sub-Title {
        font-size: 20px;
    }
    .bookingDetails .user-card-para {
        font-size: 12px;
    }
    .bookingDetails .user-card-title {
        font-size: 16px;
    }
    .notification .notification-Card {
        height: auto;
    }
    .notificationDetail-container{
        height: 32px;
    }
    .notification-btn-container{
        margin-Top: 0px;
        margin-bottom: 18px;
    }
    .notification .detail-text {
        margin-top: 0px;
    }
    .notification .user-Name {
        font-size: 16px;
    }
    .viewMap-btn-mobileView{
        text-align: center;
    }
    .bookingDetails .assignJob-Btn {
        width: 150px;
        margin-right: 0px;
    }
}


@media (max-width: 375px) {
    .profile .doodle-container img {
        left: 180px;
        top: 209px;
    }
    .bookingDetails .chat-Btn {
        width: 110px;
        font-size: 14px;
    }
    .bookingDetails .assignJob-Btn {
        width: 110px;
        font-size: 14px;
    }
    .jobDetails .accept-btn {
        width: 200px;
        font-size: 14px;
    }
    .bookingDetails .online {
        right: 128px;
        top: 375px;
    }
    .bookingDetails .profile-pic {
        top: 296px;
    }
    .contact .sub-message {
        width: 88%;
        font-size: 9px;
    }
    .chatView .chat-messenger-container {
        height: auto !important;
    }
}

@media (max-width: 320px) {
    .profile .profile-pic {
        height: 90px;
        width: 90px;
    }
    .profile .doodle-container img {
        left: 153px;
        width: 108px;
        top: 201px;
    }
    .profile .sub-Title {
        font-size: 14px;
    }
    .profile .edit-profile {
        font-size: 11px;
    }
    .editProfile .profile-pic {
        height: 90px;
        width: 90px;
    }
    .editProfile .edit-container {
        margin-left: 66px;
        margin-bottom: 20px;
    }
    .editContainer-padding{
        padding: 16px !important;
    }
    .editProfile .profile-card-container {
        padding: 42px 12px;
    }
    .profile .profile-card-container {
        padding: 42px 15px;
    }
    .profile .radioBtn-Label {
        font-size: 9px;
    }
    .profile .user-Title {
        font-size: 12px;
    }
    .profile .payment-card-default {
        margin-Left: 16px;
    }
    .bookingDetails .profile-pic {
        top: 300px;
    }
    .bookingDetails .online {
        right: 100px;
        top: 374px;
    }
    .bookingDetails .assignJob-Btn {
        width: 110px;
        font-size: 12px;
        margin-right: 10px;
    }
    .bookingDetails .chat-Btn {
        width: 110px;
        font-size: 12px;
    }
    .chatView .profile-pic {
        height: 62px;
        width: 62px;
        top: -32px;
    }
    .chatView .certificate-text {
        font-size: 12px;
    }
    .chatView .certified-badge {
        font-size: 12px;
    }
    .outgoing .chat-message-outgoing p {
        font-size: 12px;
    }
    .incoming .chat-message-incoming p {
        font-size: 12px;
    }
    .outgoing .chat-picture-outgoing {
        height: 50px;
        width: 50px;
        overflow: hidden;
        background-color: transparent;
    }
    .incoming .chat-picture-incoming {
        height: 50px;
        width: 50px;
        overflow: hidden;
        background-color: transparent;
    }
    .chat-profile {
        height: 28px;
        width: 28px;
        background-color:transparent;
    }
    .bookingDetails .profile-card-container {
        padding: 20px 14px !important;
    }
    .chatView .All-messages-container {
        padding: 26px 12px;
    }
}
