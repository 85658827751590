
/* ------active booking ------- */

.activeBooking .profile-card-container {
    background-color: #fff;
    height: 633px;
    width: 100%;
    margin-bottom: 22px;
    border-radius: 8px;
    overflow: scroll;
    /* padding: 42px 30px; */
    padding: 14px;
}

.activeBooking .pendingBooking-bg{
    width: 100%;
    height: 86px;
    background-image: url('../image/pending.png');
    background-size: contain;
    background-repeat: no-repeat;
    /* background: linear-gradient(90deg, #F6F6F6, #6648A9); */
    border-radius: 8px;
    margin-bottom: 14px;
}
.activeBooking .activeBooking-bg{
    width: 100%;
    height: 86px;
    background-image: url('../image/active.png');
    background-size: contain;
    background-repeat: no-repeat;
    /* background: linear-gradient(90deg, #F6F6F6, #15BC36); */
    border-radius: 8px;
    margin-bottom: 14px;
}
.activeBooking .jobRequest{
    width: 100%;
    height: 86px;
    background-image: url('../image/jobRequest.png');
    background-size: contain;
    background-repeat: no-repeat;
    /* background: linear-gradient(90deg, #F6F6F6, #15BC36); */
    border-radius: 8px;
    margin-bottom: 14px;
}

.activeBooking .job-btn{
    width: 145px;
    background-color: #fff;
    color: #06B82A;
    border-radius: 7px;
    font-family: 'Poppins-Medium';
    font-size: 14px;
    margin-left: 3px;
    margin-right: 20px;
    border: none;
}
.activeBooking .job-btn:hover{
    background-color: #fff;
    color: #fff;
    border: none;
}


.activeBooking .image-container {
    height: 55px;
    width: 55px;
    /* background-color: #b1b1b1; */
    border-radius: 7px;
    overflow: hidden;
}

.activeBooking .detail-text{
    font-size: 10px;
    color: #121212;
    font-family: Poppins-Medium;
    margin-top: 10px;
    text-align: center;
}

.activeBooking .notification-Btn{
    width: 150px;
    background-color: #5C3DA4;
    color: #fff;
    border-radius: 7px;
    font-family: 'Poppins-Medium';
    font-size: 12px;
    margin-left: 3px;
    margin-right: 20px;
}
.activeBooking .viewDetail{
    width: 145px;
    background-color: #06B82A;
    color: #fff;
    border-radius: 7px;
    font-family: 'Poppins-Medium';
    font-size: 14px;
    margin-right: 36px;
    border: none;
}
.activeBooking .notification-Btn:focus{
    background-color: #5C3DA4 !important; 
    color: #fff !important;
}

.activeBooking .user-Name{
    color: #121212;
    font-size: 18px;
    font-family: Poppins-SemiBold;
    margin: 0px;
}
.activeBooking .certified-text {
    font-size: 10px;
    font-family: 'Poppins-Regular';
    color: #121212;
    margin-left: 3px;
}

.gray-Color{
    background-color: #A2A2A2;
    border-radius: 8px;
    margin-bottom: 14px;
}
.activeBooking .status-text{
    color: #5C3DA4;
    font-size: 8px;
    font-family: 'Poppins-SemiBold';
    text-decoration: underline;
    margin-top: 14px;
    cursor: pointer;
}

.activeBooking .status-text2{
    color: #A2A2A2;
    font-size: 10px;
    font-family: 'Poppins-SemiBold';
    text-decoration: underline;
    margin-top: 12px;
    cursor: pointer;
}

.star-icon{
    margin-bottom: 16px;
    margin-left: 3px;
    color: #FFC107;
}

/* --- rejected booking --- */
.activeBooking .Rejected-btn{
    width: 145px;
    background-color: #A2A2A2;
    color: #fff;
    border-radius: 7px;
    font-family: 'Poppins-Medium';
    font-size: 14px;
    margin-right: 36px;
}

.activeBooking .Rejected-bg{
    width: 100%;
    height: 86px;
    background-image: url('../image/reject.png');
    background-size: contain;
    background-repeat: no-repeat;
    /* background: linear-gradient(90deg, #F6F6F6, #DD273A); */
    border-radius: 8px;
    margin-bottom: 14px;
}



/* ------ book a drive ------- */

.driveBooking .profile-card-container {
    background-color: #fff;
    height: 499px;
    width: 100%;
    margin-bottom: 22px;
    border-radius: 8px;
    padding: 14px;
}

.driveBooking .title {
    font-size: 24px;
    font-family: Poppins-Medium;
    color: #121212;
    margin-bottom: 26px;
}

.driveBooking .search-container input{
    width: 100%;
    height: 44px;
    border-radius: 10px;
    padding-left: 52px;
    border: 1px solid #DFDFDF;
    background: #ffffff;
   
}
.driveBooking .search-container input::placeholder{
   font-size: 18px;
   color: #12121259;
}

.driveBooking .search-container {
    margin-bottom: 50px;
    color: #121212;
    font-family: Poppins-Regular;
}

.driveBooking .chat-Btn{
    width: 188px;
    border-radius: 7px;
    background-color: #4CA5F3;
    color: #fff;
    font-family: Poppins-SemiBold;
}
.driveBooking .chat-Btn:active{
    background-color: #4CA5F3 !important;
    border-color: #10707f !important;
}
.driveBooking .chat-Btn:hover{
    background-color: #4CA5F3 !important;
    border-color: #10707f !important;
}
.driveBooking .chat-Btn:focus{
    background-color: #4CA5F3 !important;
    border-color: #10707f !important;
}
.driveBooking .image-container{
    height: 55px;
    width: 55px;
    background-color: #b1b1b1;
    border-radius: 7px;
    overflow: hidden;
}

.driveBooking .user-name{
    font-family: Poppins-Regular;
    font-size: 18px;
    color: #121212;
}
.driveBooking .user-name:hover{
  text-decoration: underline;
}

.driveBooking .driver-detail{
    font-size: 17px;
    font-family: 'Poppins-Regular';
    color: #12121287;

}

.search-container .search-icon{
    position: absolute;
    top: 176px;
    left: 362px;
    color: #0000003b;
    font-size: 17px;
}

.driveBooking .certified-badge {
    margin-right: 10px;
    margin-bottom: 18px;
    color: #00000085;;
}

.flexBox-style{
    display: flex;
    justify-content: center; 
    align-items: center;
}
.flexBox-style-space{
    display: flex;
    justify-content: space-between; 
    align-items: center;
}

.back-container {
    background-color: #fff;
    height: 30px;
    width: 30px !important;
    border-radius: 50%;
    position: absolute;
    top: 78px;
}
.back-container:hover {
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}
.back-container .back-icon{
    font-size: 26px;
    position: relative;
    margin-top: 2px;
    margin-left: -13px;
}


/* ------provider profile ------- */

.providerProfile .profile-card-container {
    background-color: #fff;
    height: 619px;
    width: 100%;
    margin-bottom: 22px;
    border-radius: 8px;
    padding: 42px 30px;
}
.providerProfile .profile-pic {
    height: 80px;
    width: 80px;
    background-color: #b5b5b5;
    position: absolute;
    top: 148px;
    border-radius: 8px;
    overflow: hidden;
}
.providerProfile .user-name{
    color: #121212;
    font-size: 30px;
    font-family: Poppins-SemiBold;
    text-align: center;
    margin-top: 12px;
}

.providerProfile .sub-Title{
    color: #121212;
    font-size: 24px;
    font-family: Poppins-Medium;
}

.providerProfile .certificate-text{
    margin-left: 20px;
    margin-right: 20px;
    color: #12121266;
}

.providerProfile .certified-badge{
    margin-bottom: 12px;
    color: #12121266;
}

.providerProfile .rating{
    margin-right: 10px;
    color: #12121266;
}

.providerProfile .star-icon{
    margin-bottom: 15px;
}

.providerProfile .provider-card{
    height: 100px;
    width: 100%;
    background-color: #F6F6F8;
    border-radius: 13px;
    border-color: #DFDFDF;
    display: flex;
    justify-content: center;
    align-items: center;
}
.providerProfile .provider-card-title{
    margin-left: 15px;
    font-size: 15px;
    font-family: 'Poppins-SemiBold';
    color: #5C3DA4;
    margin-top: 14px;
}

.providerProfile .about-container{
    width: 100%;
    border: 1px solid #DFDFDF;
    height: 213px;
    margin-top: 14px;
    border-radius: 8px;
    background-color: #F6F6F8;
    padding: 12px 26px;
    overflow-y: scroll;
}

.providerProfile .about-para{
    font-size: 15px;
    color: #1212126b;
    font-family: 'Poppins-Regular';
}

.providerProfile .goBack-Btn{
    background-color: #D99329;
    color: #ffff;
    width: 200px !important;
    border-radius: 8px;
    font-family: Poppins-Medium;
    font-size: 18px;
}
.providerProfile .assignJob-Btn{
    background-color: #5C3DA4;
    color: #ffff;
    width: 200px;
    border-radius: 8px;
    font-family: Poppins-Medium;
    font-size: 18px;
}
.providerProfile .chat-Btn{
    background-color: #4CA5F3;
    color: #ffff;
    width: 200px;
    border-radius: 8px;
    font-family: Poppins-Medium;
    font-size: 18px;
}

/* --- Buttons --- */

.btn-primary:focus{
    background-color: #5C3DA4 !important;
    color: #ffff !important;
}
.btn-warning:hover{
    background-color: #d5b380  !important;
    color: #ffff !important;
}
.btn-warning:active{
    background-color: #d5b380  !important;
    color: #ffff !important;
}
.btn-warning:focus{
    background-color: #d5b380  !important;
    color: #ffff !important;
}
.btn-info:hover{
    background-color: #699cc9 !important;
    color: #ffff !important;
}
.btn-info:active{
    background-color: #4CA5F3 !important;
    color: #ffff !important;
}
.btn-info:focus{
    background-color: #4CA5F3 !important;
    color: #ffff !important;
}


/* ------Edit Payment ------- */

.new-Payment .profile-card-container {
    background-color: #fff;
    height: 633px;
    width: 100%;
    margin-bottom: 22px;
    border-radius: 8px;
    /* padding-top: 42px;
    padding-left: 30px; */
    padding: 42px 30px;
}
.new-Payment .profile-pic {
    height: 110px;
    width: 110px;
    /* background-color: #b5b5b5; */
    position: absolute;
    top: 110px;
    border-radius: 8px;
    overflow: hidden;
}
.new-Payment .user-name{
    color: #121212;
    font-size: 30px;
    font-family: Poppins-SemiBold;
    text-align: center;
    margin-top: 40px;
}

.new-Payment .sub-Title{
    color: #121212;
    font-size: 24px;
    font-family: Poppins-Medium;
}

.new-Payment .edit-profile{
    color:#A2A2A2;
    font-size: 12px;
    font-family: Poppins-Regular;
}

.new-Payment .user-Detail-container{
    height: 356px;
    width: 100%;
    background-color: #F6F6F6;
    border-color: #DFDFDF;
}

.new-Payment .user-Title{
    color: #121212;
    font-size: 18px;
    font-family: Poppins-SemiBold;
    margin: 0;
}
.new-Payment .user-data{
    color: #5C3DA4;
    font-size: 16px;
    font-family: Poppins-Medium;
}

.new-Payment .addNew-Btn{
    width: 148px;
    border-radius: 8px;
    background-color: #5C3DA4;
    color: #fff;
}

.new-Payment .radioBtn-Label{
    color: #A2A2A2;
    font-family: Poppins-Regular;
    font-size: 12px;
    margin-right: 8px;
}

.new-Payment .doodle-container img{
    position: absolute;
    top: 153px;
    left: 504px;
    width: 134px;
    left: 761px;
}

.new-Payment .save-container{
    text-align: center;
    margin-top: 25px;
}

.new-Payment .savePayment-Btn{
    width: 200px;
    border-radius: 8px;
    background-color: #5C3DA4;
    color: #fff;
    font-size: 18px;
    font-family: Poppins-Regular;
}
.new-Payment .payment-card-details{
    width: 100%;
    height: 420px;
}

.new-Payment .user-Card-details{
    background-color: #F6F6F6;
    height: 100%;
}

.new-Payment .card-View{
    padding: 20px 44px;
}
.new-Payment .card-View .userDetails{
    font-size: 18px;
    font-family: Poppins-SemiBold;
    color: #121212;
}
.new-Payment .card-View .userDetails-text{
    font-size: 16px;
    font-family: Poppins-SemiBold;
    color: #5C3DA4;
}

.new-Payment .paymentCard-vector{
    margin-Top: 28px;
} 

.new-Payment .paymentCard-vector img{
    width: 158px;
} 

.new-Payment .payment-card{
   margin-top: 28px;
}


/* ------Request Confirmation ------- */

.req-confirm .profile-card-container {
    background-color: #fff;
    height: 633px;
    width: 100%;
    margin-bottom: 22px;
    border-radius: 8px;
    padding: 42px 30px;
}
.req-confirm .profile-pic {
    height: 110px;
    width: 110px;
    background-color: #b5b5b5;
    position: absolute;
    top: 225px;
    border-radius: 8px;
}
.req-confirm .user-name{
    color: #121212;
    font-size: 30px;
    font-family: Poppins-SemiBold;
    text-align: center;
    margin-top: 140px;
}

.req-confirm .sub-Title{
    color: #121212;
    font-size: 24px;
    font-family: Poppins-Medium;
}

.req-confirm .edit-profile{
    color:#A2A2A2;
    font-size: 12px;
    font-family: Poppins-Regular;
}

.req-confirm .user-Detail-container{
    height: 356px;
    width: 100%;
    background-color: #F6F6F6;
    border-color: #DFDFDF;
}

.req-confirm .user-Title{
    color: #121212;
    font-size: 18px;
    font-family: Poppins-SemiBold;
    margin: 0;
}
.req-confirm .user-data{
    color: #5C3DA4;
    font-size: 16px;
    font-family: Poppins-Medium;
}


.req-confirm .save-container{
    text-align: center;
    margin-top: 25px;
}

.req-confirm .savePayment-Btn{
    width: 200px;
    border-radius: 8px;
    background-color: #5C3DA4;
    color: #fff;
    font-size: 18px;
    font-family: Poppins-Regular;
}

.req-confirm .certificate-text{
    margin-left: 12px;
    margin-right: 20px;
    color: #12121266;
}

.req-confirm .certified-badge{
    margin-bottom: 12px;
    color: #12121266;
}

.req-confirm .rating{
    margin-right: 10px;
    color: #12121266;
}

.req-confirm .star-icon{
    margin-bottom: 15px;
}

.req-confirm .status-card{
    height: 80px;
    width: 100%;
    background-color: #F6F6F6;
    border-radius: 12px;
    border-color: #DFDFDF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.req-confirm .status-text{
    font-size: 20px;
    font-family: Poppins-SemiBold;
    color: #121212;
    margin-top: 16px;
    text-align: center;
}

.req-confirm .goToBooking{
    color: #5C3DA4;
    font-family: 'Poppins-Regular';
    font-size: 18px;
    text-align: center;
    text-decoration: underline;
    margin-top: 32px;
}
.req-confirm .goToBooking:hover{
    text-decoration: none;
}


.booking-certified-badge{
    margin-top: -18px;
    font-size: 10px !important;
    color: #0000007a;
}

.flexBox-align{
    display: flex;
    align-items: center;
}

.rating-container{
    display: flex;
    justify-Content: center;
    align-Items: center;
    height: 86px;
    margin-Left: 18px;
}

.booking-btn-container{
    text-Align: center;
     margin-Top: 20px ;
}

.activeBookingDate-container{
    display: flex;
    justify-Content: center;
    align-Items: center;
    height: 86px;
}

.activeBooking-footer{
    display: flex;
    justify-Content: space-between;
    align-Items: center;
}

.driverCard-mainContainer{
    display: flex;
    align-Items: center;
    height: 86px;
    margin-Left: 28px;
    text-Decoration: none;
}
.providerchat-btn-container{
    margin-top: 32px;
    float:right;
}

.jobRequest-date-container{
    display: flex;
    justify-Content: center;
    align-Items: center;
    height: 86px;
}
.viewDetails-container{
    margin-Top: 20px; 
    float: right;
} 

.jobcard-container{
    float: left; 
    margin-Left: 18px; 
    height: 74px; 
}

.custom-profile-card{
    height: 648px; 
    padding: 14px 120px;
} 

.delivery-card{
    padding: '10px 82px'
}

.BookingRating-container{
    height: 86px; 
    margin-Left: 18px; 
}
           
 

@media only screen and (max-width: 1024px) {
    /* == Payment == */
    .new-Payment .profile-card-container {
        height: 572px;
    }
    .new-Payment .user-name {
        font-size: 26px;
        margin-top: 28px !important;
    }
    .new-Payment .payment-card-details {
        height: 382px;
    }
    .new-Payment .doodle-container img {
        left: 630px;
    }
    .new-Payment .user-Detail-container {
        height: 340px;
    }
    .new-Payment .save-container {
        margin-top: 15px;
    }
    .new-Payment .payment-card img{
       width: 150px;
     }

   /* == Booking == */
   .activeBooking .profile-card-container {
        height: 572px;
    }
    .activeBooking .activeBooking-bg {
        height: 77px;
        background-size: cover;
    }
    .activeBooking .pendingBooking-bg {
        height: 77px;
        background-size: cover;
    }
    .activeBooking .viewDetail {
        margin-right: 10px;
        font-size: 10px;
    }
    .activeBooking .notification-Btn {
        font-size: 10px;
        margin-left: 3px;
        margin-right: 10px;
    }
    .activeBooking .Rejected-bg {
        height: 77px;
        background-size: cover;
    }

    /* == Job Request == */
    .activeBooking .job-btn {
        width: 121px;
    }

    /*== Payment ==*/
    .new-Payment .save-container {
        margin-top: 20px;
    }
    /* previous booing */
    .rating-container span span{
        height: 10px !important;
        width: 10px !important;
    }
    .activeBooking .Rejected-btn {
        font-size: 12px;
        margin-right: 10px;
    }
    .booking-btn-container{
         margin-Top: 10px ;
    }

    /* ==job Request== */
    .activeBooking .jobRequest {
        height: 77px;
        background-size: cover;
    }

    /* == Drive Booking == */
    .driveBooking .driver-detail {
        font-size: 12px;
    }
    .driveBooking .user-name {
        font-size: 14px;
    }

    /* == Provider Profile == */
    .providerProfile .user-name {
        font-size: 24px;
        margin-top: 12px;
    }
    .providerProfile .chat-Btn {
        width: 150px;
    }
    .providerProfile .assignJob-Btn {
        width: 150px;
    }
    .providerProfile .goBack-Btn {
        width: 150px !important;
    }
    .providerProfile .provider-card-title {
        font-size: 12px;
    }

    /* Active Booking */
    .activeBooking .user-Name {
        font-size: 12px;
    }
    .activeBooking .notification-Btn {
        width: auto;
    }
    .activeBooking .viewDetail {
        width: auto;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .new-Payment .profile-pic {
        top: 160px;
    }
    .new-Payment .doodle-container img {
        left: 376px;
        top: 203px;
    }
    .rating-container {
        margin-Left: 12px;
    }
    .providerProfile .profile-pic {
        top: 194px;
    }
    .search-container .search-icon {
        top: 218px;
        left: 106px;
    }
  
}

@media (max-width: 767px) {
    .new-Payment .profile-pic {
        top: 160px;
    }
    .new-Payment .doodle-container img {
        left: 375px;
        top: 203px;
    }
    .new-Payment .payment-card img {
        width: 131px;
    }
    .new-Payment .card-View .userDetails {
        font-size: 10px;
    }
    .new-Payment .card-View .userDetails-text {
        font-size: 12px;
    }
    .activeBooking .Rejected-bg {
        padding-left: 10px;
    }
    /* .rating-container {
        margin-Left: -20px;
    } */
    .activeBooking .Rejected-btn {
        margin-right: 8px;
    }
    .search-container .search-icon {
        top: 220px;
        left: 145px;
    }

    /* == Provider Profile == */
    .providerProfile .provider-card {
        margin-bottom: 12px;
    }
    .providerProfile .provider-card-title {
        font-size: 22px;
    }
    .providerProfile .profile-pic {
        top: 194px;
    }
    .providerProfile .profile-card-container {
        height: auto;
    }
    .providerProfile .chat-Btn {
        width: 135px;
    }
    .providerProfile .assignJob-Btn {
        width: 135px;
    }
    .providerProfile .goBack-Btn {
        width: 135px !important;
    }
    .activeBooking .job-btn {
        width: auto;
    }
    .req-confirm .profile-pic {
        top: 258px;
    }
    .req-confirm .status-text {
        font-size: 14px;
    }

}

@media (max-width: 425px) {
    .new-Payment .payment-card img {
        width: 80px;
    }
    .new-Payment .doodle-container img {
        left: 205px;
    }
    .react-emoji-picker--wrapper {
        left: -29px !important;
    }
    .activeBooking .pendingBooking-bg {
        height: 168px;
    }
    .activeBooking .status-text {
        font-size: 11px;
    }
    .activeBookingDate-container{
        height: 32px;
    }
    .activeBooking-footer{
        display: flex;
        justify-Content:center ;
        align-Items: center;
    }
    .activeBooking .activeBooking-bg {
        height: 168px;
    }
    .activeBooking .status-text {
        margin-right: 6px;
    }
    .activeBooking .Rejected-bg {
        height: 168px;
    }
    .activeBooking .status-text2 {
        margin-right: 16px;
    }
    .rating-container span span {
        height: 14px !important;
        width: 14px !important;
    }
    .search-container .search-icon {
        top: 210px;
        left: 47px;
    }
    .driveBooking .driver-detail {
        font-size: 10px;
    }
    .driverCard-mainContainer {
        margin-Left: 0px;
    }
    .driveBooking .user-name {
        font-size: 11px;
    }
    .providerProfile .goBack-Btn {
        width: 200px !important;
    }
    .providerProfile .assignJob-Btn {
        width: 200px !important;
    }
    .providerProfile .chat-Btn {
        width: 200px !important;
    }
    .goBack-btn-container{
        text-align: center;
    }
    .providerchat-btn-container{
        margin-top: 32px;
        text-align: center;
        float:none;
    }
    .activeBooking .jobRequest {
        height: 168px;
    }
    .jobRequest-date-container{
        height: 32px;
    }
    .viewDetails-container{
        float:none;
        margin-Top: 10px
    } 
    .jobBtn-mobile-view{
      display: flex;
      justify-content: center !important;
      align-items: center;
    }
    .jobcard-container{
        float: none; 
    }
    .custom-profile-card{
        height: 648px; 
        padding: 14px 20px;
    } 
    .search-container .search-icon {
        top: 206px;
    }
    .new-Payment .doodle-container img {
        left: 178px;
    }
    .req-confirm .savePayment-Btn {
        width: 165px !important;
        font-size: 12px;
    }
    .req-confirm .profile-card-container {
        height: auto;
    }
    /* == active booking gradient == */
    /* .activeBooking .Rejected-bg {
        height: 168px;
        background-image: none;
        border: 1px solid #7070704f;
        background: linear-gradient( 86deg, #F6F6F6, #d14d5a30);
    }
    .activeBooking .activeBooking-bg {
        height: 168px;
        background-image: none;
        border: 1px solid #7070704f;
        background: linear-gradient(90deg, #F6F6F6, #15bc3657);
    }
    .activeBooking .pendingBooking-bg {
        height: 168px;
        background-image: none;
        border: 1px solid #7070704f;
        background: linear-gradient(
            90deg, #F6F6F6, #6648a98a);
    } */
}


@media (max-width: 320px) {
    .activeBooking .image-container {
        height: 40px;
        width: 40px;
    }
    .activeBooking .notification-Btn {
        font-size: 8px;
    }
    .nopayment-width{
        width: 100px;
    }
    .new-Payment .profile-pic {
        height: 90px;
        width: 90px;
    }
    .new-Payment .doodle-container img {
        left: 151px;
        width: 110px;
        top: 196px;
    }
    .new-Payment .savePayment-Btn {
        width: 180px;
        font-size: 16px;
    }
    .new-Payment .user-name {
        margin-top: 18px !important;
    }
    .nopayment-width {
        width: 160px;
    }
    .new-Payment .save-container {
        margin-top: 0px;
    }
    .new-Payment .profile-card-container {
        height: auto !important;
    }
    .driveBooking .image-container {
        height: 44px;
        width: 44px;
    }
    .driveBooking .certified-badge {
        font-size: 12px;
    }
    .new-Payment .payment-card {
        display: none;
    }
    .rating-container {
        margin-Left: 0px;
    }
    .delivery-card{
        padding: '10px 40px'
    }
    .req-confirm .status-text {
        font-size: 11px;
    }
    .req-confirm .profile-pic {
        top: 294px;
    }
    .BookingRating-container{
        height: 86px; 
        margin-Left: 10px; 
    }
    .providerProfile .provider-card-title {
        font-size: 12px;
    }
    .providerProfile .about-container {
        padding: 12px 12px;
    }
    .star-icon {
        margin-left: 3px;
        font-size: 11px;
        margin-right: 12px;
    }
    .providerProfile .profile-pic {
        top: 174px;
    }
    .search-container .search-icon {
        top: 201px;
    }
}
